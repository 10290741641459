.bottom {
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: rem(80px 0 0);
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  &--with-decor {
    padding: 12% 0 0;
    margin: -10% 0 0;
    &:after {
      content: "";
      @extend .full;
      z-index: 2;
      margin-top:rem(-1px);
      background: url(../img/bottom-mask-1x.png) no-repeat 50% 0;
      background-size: 100% auto;
      @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
          background-image:url(../img/bottom-mask-2x.png);
      }
      @include media-breakpoint-down(xs) {
          background-image:url(../img/bottom-mask-mobile-2x.png);
      }
    }
  }
  &:before {
      content: "";
      @extend .full;
      background-color: rgba(37,58,131,.62);
      z-index: 1;
  }
  &__inner {
    overflow: hidden;
  }
  &__text {
    padding: rem(0 0 60px);
    position: relative;
    z-index: 4;
    color:cl(white);
    font-size: rem(20px);
    line-height: 1.5;
    text-align: center;
  }
}