.section {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    background-attachment: fixed;
  }
  &:before {
      content: "";
      @extend .full;
      background-color: cl(primary-dark, .76);
  }
  &__decor {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    &:before {
      content: "";
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: rem(277px);
      background: cl(white);
      @include media-breakpoint-down(lg)  {
        height: 60.5rem;
      }
      @include media-breakpoint-down(xs)  {
        height: 4rem;
      }
    }
    &:after {
      content: "";
      @extend .full;
      z-index: 2;
      background: url(../img/bottom-mask-1x.png) no-repeat 50% 17.1875rem;
      background-size: 100% auto;
      @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
          background-image:url(../img/bottom-mask-2x.png);
      }
      @include media-breakpoint-down(xs) {
          background-image:url(../img/bottom-mask-mobile-2x.png);
      }
      @include media-breakpoint-down(lg) {
        background-position: 50% 60rem;
      }
      @include media-breakpoint-down(xs) {
        background-position: 50% 4rem;
      }
    }
  }
}