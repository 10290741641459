.information {
    padding: rem(70px 0);
    position: relative;
    color:cl(white);
    z-index: 10;
    @include media-breakpoint-down(xs) {
        padding: 5rem 0 2rem;
    }
    &__text {
        font-size: rem(18px);
        line-height: 1.5;
        ul {
            li {
                list-style: none;
                padding: rem(0 0 0 25px);
                margin: rem(0 0 15px);
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left:0;
                    top:rem(9px);
                    width:rem(9px);
                    height: rem(9px);
                    border-radius: 50%;
                    background-color: cl(orange);
                }
            }
        }
    }
    &__side {
        position: relative;
        @include media-breakpoint-down(md) {
            margin-bottom: 3rem;
        }
    }
    &__image {
        display: block;
        width:100%;
        height: auto;
        box-shadow:rem(0 3px 51px cl(black, .34));
        border-radius: rem(30px);
        position: relative;
        z-index: 2;
    }
    &__leaf {
      position: absolute;
      width: rem(200px);
      height: auto;
      transform: rotate(-70deg) scaleX(-1);
      right: 75%;
      bottom: rem(-130px);
      @include media-breakpoint-down(xs) {
        width: rem(100px);
        right: 65%;
        bottom: -4rem;
      }
    }
}