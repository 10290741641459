.marquee {
  width:100%;
  font-size: rem(200px);
  line-height: rem(200px);
  margin-bottom: rem(-24px);
  @include media-breakpoint-down(xs) {
    font-size: rem(100px);
    line-height: rem(100px);
    margin-bottom: -0.8rem;
  }
  &__inner {
    display:inline-block;
    //padding:0 0 0 100%;
    animation: marquee 200s infinite linear;
    position: relative;
    z-index: 3;
    white-space: nowrap;
  }
  span {
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    text-shadow:rem(0 11px 106px cl(black, .53));
    color:cl(white, .8);
    font-weight: 700;
    em {
      position: relative;
      font-style: normal;
      &:before {
        content: "";
        width:rem(100px);
        height: rem(150px);
        position: absolute;
        background: url(../img/svg/leaf-1.svg) no-repeat 50% 50%;
        background-size: contain;
        transform: rotate(70deg);
        bottom: 10%;
        left: 53%;
        @include media-breakpoint-down(xs)  {
          width:rem(50px);
          height: rem(100px);
        }
      }
    }
  }
}
@-webkit-keyframes marquee {
  0% {transform: translate(0, 0);}
  100% {transform: translate(-100%, 0)}
}
@keyframes marquee{
  0% {transform: translate(0, 0);}
  100% {transform: translate(-100%, 0)}
}