$font: 'Open Sans', sans-serif;

$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "primary-dark"   : "primary-dark",
        "text" : "text",
        "gray" : "gray",
        "black" : "black",
        "white" : "white",
        "red" : "red",
        "orange" : "orange",
        "aqua-blue" : "aqua-blue",
        "blue" : "blue",
        "twitter" : "twitter",
        "facebook" : "facebook"
);

$colors : (
        'primary'  : #0f2a64,
        'secondary'  : #276750,
        'primary-dark'  : #253786,
        'text'  : #333333,
        'gray'  : #aaaaaa,
        'white' : #FFFFFF,
        'black' : #000000,
        'red'  : #c0372f,
        'orange'  : #ec6b18,
        'aqua-blue'  : #58a593,
        'blue'  : #1466b1,
        'twitter'  : #1892df,
        'facebook'  : #1c3f95,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color!important;
    }
  }
}
.bg {
  @each $status, $color in $colors {
    &--#{$status} {
      background-color: $color!important;
    }
  }
}
.border-color {
  @each $status, $color in $colors {
    &--#{$status} {
      border-color: $color!important;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}