.slider {
  margin: rem(0 0 35px);
  padding: rem(0 55px);
  @include media-breakpoint-down(xs) {
    padding: rem(0 35px);
  }
  &__item {
    height: rem(500px);
    @include media-breakpoint-down(xs) {
      height: rem(250px);
    }
    img {
      display: block;
      width:100%;
      height: 100%!important;
      max-width: 100%!important;
      object-fit: cover;
      margin: 0!important;
    }
  }
  &__prev,
  &__next {
    left:0;
    @extend .pos-centerY;
    z-index: 200;
    width:rem(45px);
    height: rem(45px);
    overflow: hidden;
    border-radius: 50%;
    background: cl(secondary);
    color:cl(white);
    border:0;
    font-size: rem(20px);
    box-shadow:rem(0 5px 20px cl(black, .15));
    @include media-breakpoint-down(xs) {
      width:rem(30px);
      height: rem(30px);
      font-size: rem(16px);
      left:-.5rem;
    }
    &:hover,
    &:focus {
      background: cl(primary);
    }
  }
  &__next {
    left:auto;
    right:0;
    @include media-breakpoint-down(xs) {
      right:-.5rem;
    }
  }
}