.footer {
  background: cl(white);
  padding: rem(50px 0 0);
  color:cl(text);
  font-size: rem(15px);
  line-height:calc(25/15);
  @include media-breakpoint-down(xs){
    margin-bottom: 3.2rem;
  }
  p {
    margin: 0;
  }
  a {
    color:cl(text);
    &:hover {
      text-decoration: none;
      color:cl(secondary);
    }
  }
  &__title {
    font-size: rem(15px);
    margin: rem(0 0 10px);
    text-transform: uppercase;
  }
  &__bottom {
    background: cl(primary);
    padding: rem(12px 0);
  }
  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:rem(10px);
    @include media-breakpoint-down(lg) {
      justify-content: center;
      margin-bottom: rem(30px);
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(42px);
      border-width:1px;
      border-style: solid;
      transition: transform .3s ease;
      &:hover {
        text-decoration: none;
        transform:scale(1.1);
      }
      i {
        font-size: rem(18px);
      }
    }
  }
  &__menu {
    list-style: none;
  }
  &__copy {
    display: block;
    color:cl(white);
    font-size: rem(14px);
  }
  &__mail {
    // display: inline-flex;
    // justify-content: center;
    // align-items: center;
    // @include circle(35px);
    // border:1px solid cl(primary);
    // background: cl(primary);
    // transition: background .3s ease;
    padding: rem(0 5px 0 0);
    color:cl(primary)!important;
    &:hover {
      text-decoration: none;
      color:cl(secondary)!important;
    }
    i {
      font-size: rem(15px);
    }
  }
}

