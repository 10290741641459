.custom {
  padding: rem(0 0 80px);
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: rem(0 0 50px);
  }
  &__inner {
    letter-spacing: rem(.2px);
    font-size: rem(18px);
    line-height: calc(28 / 18);
    h2 {
      @extend .ttu;
      color:cl(black);
      font-size: rem(30px);
      font-weight: 700;
      margin: rem(0 0 10px);
    }
  }
  &__list {
    counter-reset: item;
    padding: 0!important;
  }
  &__item {
    position: relative;
    overflow: hidden;
    padding: rem(60px 0 0);
    margin: rem(0 0 20px);
    &:before {
      content: counter(item);
      counter-increment: item;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @extend .pos-centerX;
      top:0;
      width:rem(44px);
      height: rem(44px);
      border: rem(3px solid cl(white));
      border-radius: 50%;
      background: cl(secondary);
      color:cl(white);
      font-size: rem(20px);
      line-height: 1;
      font-weight: 700;
      z-index: 2;
    }
    &:after {
      content: "";
      @extend .pos-centerX;
      top:rem(22px);
      height: 1px;
      width:rem(150px);
      background: cl(secondary);
      z-index: 1;
    }
    h2 {
      font-size: rem(32px)!important;
      text-transform: none!important;
    }
    p {

    }
    ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
  }
}