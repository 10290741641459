.students {
  position: relative;
  z-index: 3;
  padding: rem(70px 0 60px);
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  .container {
    position: relative;
  }
  &__leaf {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(70deg);
    left: 96%;
    bottom: -40%;
    filter: drop-shadow(2px 4px 0px white);
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      right: 2rem;
      left:auto;
      bottom: -5rem;
    }
  }
  &__slider {
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      padding: rem(0 50px);
    }
    @include media-breakpoint-down(xs) {
      padding: rem(0 32px);
    }
    &__prev,
    &__next {
      @extend .pos-centerY;
      left:rem(-80px);
      width:rem(50px);
      height: rem(50px);
      overflow: hidden;
      border-radius: 50%;
      background: cl(secondary);
      color:cl(white);
      border:0;
      font-size: rem(24px);
      box-shadow:rem(0 5px 20px cl(black, .38));
      z-index: 200;
      &:hover,
      &:focus {
        background: cl(white);
        color:cl(secondary);
      }
      @include media-breakpoint-down(lg) {
        left:0;
      }
      @include media-breakpoint-down(xs) {
        width:rem(32px);
        height: rem(32px);
        font-size: rem(16px);
      }
    }
    &__next {
      left:auto;
      right:rem(-80px);
      @include media-breakpoint-down(lg) {
        right:0;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(160px);
    padding: rem(30px);
    margin: rem(15px);
    position: relative;
    background: cl(white);
    border-radius: rem(15px);
    box-shadow:rem(0 0 15px cl(black, .16));
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform .3s ease;
    }
  }
}