.meetings {
  display: flex;
  flex-wrap: wrap;
  --gap: 2rem;
  gap:1rem var(--gap);
  margin: rem(0 0 20px);
  &__item {
    background: cl(white);
    font-size: rem(16px);
    --cols: 2;
    flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
    border-radius: rem(20px);
    padding: rem(20px);
    box-shadow: rem(0 15px 30px cl(black,.18));
    
    @include media-breakpoint-down(xs) {
      --cols: 1;
    }
    p {
      margin: rem(0 0 10px)!important;
    }
    a {
      &:hover {
        color:cl(secondary);
      }
    }
  }
  &__date{
    margin: rem(0 0 15px)!important;
    time {
      font-weight: 700;
    }
  }
}